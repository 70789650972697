const mobileMenu = document.querySelector('.js-menu-container');
const toggleMenuBtn = document.querySelector('.js-toggle-menu');
const iconCloseMenu = document.getElementById('icon-close-menu');
const iconMenu = document.getElementById('icon-menu');
const servicesCls = document.getElementById('services-cls');
const services = document.getElementById('services');
const subMenu = document.getElementById('sub-menu');
const contactUsBtn = document.querySelector(".btn-contact-us")

const overviewMobLink = document.getElementById('overviewMobLink');
const collaborationMobLink = document.getElementById('collaborationMobLink');
const contactUsMobLink = document.getElementById('contactUsMobLink');

const toggleMenu = () => {
  const isMenuOpen =
    toggleMenuBtn.getAttribute('aria-expanded') === 'true' || false;
  toggleMenuBtn.setAttribute('aria-expanded', !isMenuOpen);
  mobileMenu.classList.toggle('is-open');
  subMenu.classList.remove('is-open');
  iconCloseMenu.classList.toggle('is-menu-open');
  iconMenu.classList.toggle('is-menu-open');
  document.body.classList.toggle('is-open-body');
};
const closeMenu = () => {
  const isMenuOpen =
    toggleMenuBtn.getAttribute('aria-expanded') === 'true' || false;
  toggleMenuBtn.setAttribute('aria-expanded', !isMenuOpen);
  mobileMenu.classList.remove('is-open');
  subMenu.classList.remove('is-open');
  iconCloseMenu.classList.remove('is-menu-open');
  iconMenu.classList.remove('is-menu-open');
  document.body.classList.remove('is-open-body');
};

const openSubMobMenu = e => {
  e.preventDefault();
  subMenu.classList.add('is-open');
};

const closeSubMobileMenu = () => {
  subMenu.classList.remove('is-open');
};

overviewMobLink.addEventListener('click', toggleMenu);
collaborationMobLink.addEventListener('click', toggleMenu);
contactUsMobLink.addEventListener('click', toggleMenu);
contactUsBtn.addEventListener('click', closeMenu);

toggleMenuBtn.addEventListener('click', toggleMenu);
services.addEventListener('click', openSubMobMenu);
servicesCls.addEventListener('click', closeSubMobileMenu);


// Close the mobile menu on wider screens if the device orientation changes
window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
  if (!e.matches) return;
  mobileMenu.classList.remove('is-open');
  toggleMenuBtn.setAttribute('aria-expanded', false);
});
