const servicesLink = document.getElementById('servicesLink');
const machineLrngLink = document.getElementById('machineLrngLink');
const rpaLink = document.getElementById('rpaLink');
const idpLink = document.getElementById('idpLink');
const mbotLink = document.getElementById('mbotLink');
const computerVisionLink = document.getElementById('computerVisionLink');
const dataScinceLink = document.getElementById('dataScinceLink');

const collaborationLink = document.getElementById('collaborationLink');

const dropDownMenu = document.querySelector('.drop-menu__list');
const btnServices = document.querySelector('.services');

// mob menu links
const overviewMobLink = document.getElementById('overviewMobLink');
const collaborationMobLink = document.getElementById('collaborationMobLink');

// footer nav links
const collaborationFooter = document.getElementById('collaborationFooter');
const overviewFooter = document.getElementById('overviewFooter');
const overviewFooter2 = document.getElementById('overviewFooter2');
const benefitsFooter = document.getElementById('benefitsFooter');
const projectFooter = document.getElementById('projectFooter');

// const mainUrl = 'http://localhost:1234/';
// const githabPagesUrl = 'https://nzend.github.io/mbit-frontend/';
const hostUrl = 'https://mbit-consultants.com/';

const currentUrl = window.location.href;
// const tempUrl = currentUrl.includes('vercel') ? hostUrl : mainUrl;

const toggleServices = e => {
  e.preventDefault();
  if (window.screen.width < 1199) {
    const isMenuOpen =
      btnServices.getAttribute('aria-expanded') === 'true' || false;
    btnServices.setAttribute('aria-expanded', !isMenuOpen);

    dropDownMenu.classList.toggle('is-open');
  }
  return;
};

const redirectToHome = e => {
  const currentHref = window.location.href;

  if (
    currentHref.includes('services') ||
    currentHref.includes('machine-learning') ||
    currentHref.includes('robotic-process-automation') ||
    currentHref.includes('intelligent-document-processing') ||
    currentHref.includes('computer-vision') ||
    currentHref.includes('data-scince') ||
    currentHref.includes('terms') ||
    currentHref.includes('mbot') 
  ) {
    const section = e.currentTarget.dataset.section;
    window.location.assign(hostUrl + `#${section}`);
  }
  return;
};

// ---------- HEADER NAVIGATION -----------------
btnServices.addEventListener('click', toggleServices);



overviewMobLink.addEventListener('click', redirectToHome);

collaborationLink.addEventListener('click', redirectToHome);

collaborationMobLink.addEventListener('click', redirectToHome);

servicesLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'services.html');
});

machineLrngLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'machine-learning.html');
});
rpaLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'robotic-process-automation.html');
});
idpLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'intelligent-document-processing.html');
});
computerVisionLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'computer-vision.html');
});
dataScinceLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'data-scince.html');
});
mbotLink.addEventListener('click', () => {
  window.location.assign(hostUrl + 'mbot.html');
});

// ------- FOOTER NAVIFATION -------------
collaborationFooter.addEventListener('click', redirectToHome);
overviewFooter.addEventListener('click', redirectToHome);
overviewFooter2.addEventListener('click', redirectToHome);
benefitsFooter.addEventListener('click', redirectToHome);
projectFooter.addEventListener('click', redirectToHome);
