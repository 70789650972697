import { getLang, setLang } from './local-storage';
import { langArr } from '../data/translate';

const switchLangBtn = document.querySelectorAll('.leng__switcher-radio');
const enLabel = document.querySelector('.leng__switcher--en');
const ukLabel = document.querySelector('.leng__switcher--uk');
const enLabelMob = document.querySelector('.leng__switcher--en-mob');
const ukLabelMob = document.querySelector('.leng__switcher--uk-mob');

let activeLang = getLang() || 'en';

const addClassToLabel = lang => {
  enLabel.classList.remove(
    'leng__switcher-label-checked',
    'leng__switcher-label-hide'
  );
  ukLabel.classList.remove(
    'leng__switcher-label-checked',
    'leng__switcher-label-hide'
  );
  enLabelMob.classList.remove(
    'leng__switcher-label-checked',
    'leng__switcher-label-hide'
  );
  ukLabelMob.classList.remove(
    'leng__switcher-label-checked',
    'leng__switcher-label-hide'
  );

  if (lang === 'en') {
    // Приховуємо кнопку для 'en', залишаємо 'uk'
    enLabel.classList.add('leng__switcher-label-hide');
    enLabelMob.classList.add('leng__switcher-label-hide');
  } else if (lang === 'uk') {
    // Приховуємо кнопку для 'uk', залишаємо 'en'
    ukLabel.classList.add('leng__switcher-label-hide');
    ukLabelMob.classList.add('leng__switcher-label-hide');
  }
};

addClassToLabel(activeLang);

const changeActiveLang = () => {
  switchLangBtn.forEach(radio => {
    if (radio.checked) {
      setLang(radio.value);
      addClassToLabel(radio.value);
    }
  });
};

const languageRadios = document.querySelectorAll('.leng__switcher-radio');

function changeLanguage(selectedLanguage) {
  const currentUrl = new URL(window.location.href);

  currentUrl.searchParams.set('lang', selectedLanguage);
  window.history.replaceState({}, '', currentUrl);

  activeLang = selectedLanguage;
  for (let key in langArr) {
    let elem = document.querySelector('.lng-' + key);
    if (elem) {
      elem.innerHTML = langArr[key][activeLang];
    }
  }
}

languageRadios.forEach(radio => {
  radio.addEventListener('change', event => {
    const selectedLanguage = event.currentTarget.value;
    changeActiveLang();
    changeLanguage(selectedLanguage);
  });
});

function addAlternateLinks() {
  const currentUrl = new URL(window.location.href);
  const availableLanguages = ['en', 'uk']; // Доступні мови на вашому сайті

  // Видалення параметра мови з URL-адреси
  currentUrl.searchParams.delete('lang');
  const baseUrl = currentUrl.origin + currentUrl.pathname; // Базова URL-адреса без параметрів

  // Створення посилань для кожної доступної мови
  availableLanguages.forEach(language => {
    // Створення посилання з використанням базової URL-адреси і мови
    const alternateLink = document.createElement('link');
    // console.log(alternateLink);
    alternateLink.rel = 'alternate';
    currentUrl.searchParams.set('lang', language);
    alternateLink.href = currentUrl.href; // Додавання параметра мови
    alternateLink.hreflang = language;
    // console.log(alternateLink);

    // Додавання посилання до <head> сторінки
    document.head.appendChild(alternateLink);
  });
}

addAlternateLinks();

changeLanguage(activeLang);
