// Тут додаємо імпорт готового модулю за зразком:

import * as mobilMenu from './mobile-menu';
import * as navifationMenu from './navigation-menu';
import * as contactUs from './contact-us';
import * as sectionAnimation from './section-animation';
// import * as backToTopBtn from './back-to-top-btn';
import * as changeLang from './change-lang';
import * as servicesSlider from './services-slider';
import * as headerTheme from './header-theme';
// import * as mbotVideoController from './mbotVideoController';
