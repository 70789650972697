import axios from 'axios';
import Notiflix from 'notiflix';
const MAIN_URL = 'https://mbit-rest-api.onrender.com';

export async function sendMessage(data) {
  const url = `${MAIN_URL}/api/message`;
  return await axios
    .post(url, data)
    .then(response => {
      Notiflix.Notify.success('Message sent successfully');
      return response.data;
    })
    .catch(error => {
      Notiflix.Notify.failure('Something went wrong, please try again');
      console.log(error);
    });
}
