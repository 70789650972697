const header = document.querySelector('.header');

const currentHref = window.location.href;
const headerTheme = () => {
  if (
    currentHref.includes('services') ||
    currentHref.includes('machine-learning') ||
    currentHref.includes('robotic-process-automation') ||
    currentHref.includes('intelligent-document-processing') ||
    currentHref.includes('computer-vision') ||
    currentHref.includes('data-scince')
  ) {
    header.classList.add('header--light');
  }
};
headerTheme();
