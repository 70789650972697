import { sendMessage } from './api';
import Notiflix from 'notiflix';
import { getLang, setLang } from './local-storage';

const contactUsForm = document.querySelector('.callback');
const mainBtn = document.querySelector('.main__button');

const handleSender = async e => {
  e.preventDefault();

  if (getLang() === 'uk') {
    mainBtn.textContent = 'Надсилаємо...';
  } else if (getLang() === 'en') {
    mainBtn.textContent = 'Sending...';
  }

  mainBtn.disabled = true;

  if (e.target.name.value.trim() === '') {
    return Notiflix.Notify.failure('Something went wrong, please try again');
  }
  const data = {
    name: e.target.name.value,
    email: e.target.email.value,
    message: e.target.message.value,
    company: e.target.company.value,
    position: e.target.position.value,
  };

  await sendMessage(data);
  if (getLang() === 'uk') {
    mainBtn.textContent = 'Надіслати';
  } else if (getLang() === 'en') {
    mainBtn.textContent = 'Send';
  }

  mainBtn.disabled = false;
  e.target.message.value = '';
  e.target.email.value = '';
  e.target.name.value = '';
  e.target.company.value = '';
  e.target.position.value = '';
};
contactUsForm.addEventListener('submit', handleSender);
