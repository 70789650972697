const KEY_CURRENT_LANGUAGE = 'currentLanguage';


export function getLang() {
  return JSON.parse(localStorage.getItem(KEY_CURRENT_LANGUAGE));
}


export function setLang(lang) {
  localStorage.setItem(KEY_CURRENT_LANGUAGE, JSON.stringify(lang));
}